<template>
  <div
    id="page-drone"
    class="main-content py-2 overflow-hidden bg-dark position-relative"
  >
    <video
      id="videoElement"
      muted
      :class="{ 'd-none': !drone || !drone.isOnline }"
    >
      <source id="mp4" type="video/mp4" />
    </video>
    <img
      class="position-absolute"
      src="@/assets/place_holder.jpg"
      alt=""
      style="top: 0; left: 0"
      v-if="!drone || !drone.isOnline"
    />
    <button
      type="button"
      class="btn btn-primary text-nowrap"
      style="width: 200px"
      @click="viewDetection(drone)"
      v-if="drone"
    >
      <i class="fas fa-eye mr-2"></i>
      View detections
    </button>
  </div>
</template>

<script>
import API from "@/api";
import flvjs from "flv.js";

export default {
  name: "drone",
  data() {
    return {
      apiHost: API.apiHost(),
      drone: null,
      flvPlayer: null,
    };
  },
  methods: {
    viewDetection() {
      this.$router.push({
        name: "Detections",
        query: {
          cctv: this.drone.id,
        },
      });
    },
    droneIsOffline() {
      this.drone.isOnline = false;
    },
    getDrone() {
      API.get("cameras?type=drone")
        .then((retVal) => {
          if (retVal.data.length > 0) {
            this.drone = retVal.data[0];

            this.loadVideo();
          } else {
            this.drone = null;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    loadVideo() {
      if (this.drone) {
        if (flvjs.isSupported()) {
          let videoElement = document.getElementById("videoElement");

          if (typeof this.flvPlayer !== "undefined") {
            if (this.flvPlayer != null) {
              this.flvPlayer.unload();
              this.flvPlayer.detachMediaElement();
              this.flvPlayer.destroy();
              this.flvPlayer = null;
            }
          }

          let mp4 = document.getElementById("mp4");

          videoElement.controls = false;

          mp4.src = null;

          this.flvPlayer = flvjs.createPlayer({
            type: "flv",
            url: this.drone.streamingUrl,
            hasAudio: false,
            enableStashBuffer: false,
            isLive: true
          });

          this.flvPlayer.attachMediaElement(videoElement);
          this.flvPlayer.load();
          // this.flvPlayer.play();

        }
      }
    },
  },
  mounted() {
    this.getDrone();

    // setInterval(() => {
    //   this.getDrone();
    // }, 180000);

    flvjs.LoggingControl.addLogListener((_, str) => {
      if (str.includes("onSourceEnded")) {
        
        if (this.drone) {
          this.drone.isOnline = false;
        }
        this.loadVideo();
      }

      if (str.includes("onSourceOpen")) {
      }

      if (str.includes("Received Initialization")) {
        if (typeof this.flvPlayer !== "undefined" && this.flvPlayer != null) {
          if (this.drone) {
            this.drone.isOnline = true;
          }
          this.flvPlayer.play();
        }
      }
    });
  },
};
</script>